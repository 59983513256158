/* colours */
$primary: #cf5c34; /*orange*/
$secondary: #0C334A; /*blue-grey*/
$tertiary: #0C334A; /*navy*/

$light: #eff3f4; /*offwhite*/
$lgrey: #BBBDBF; /*light grey*/
$mgrey: #58595B; /*medium grey*/

/* fonts */
$text: yantramanav, sans-serif;
$heading: lato, sans-serif;

/* shared */
$div-radius: 20px;
$button-radius: 30px;
$transition: all 0.3s ease;

/* helper functions and reusable blocks */
@function tint($colour: $tertiary, $percentage: 70%){
    @return mix(white, $colour, $percentage);
}

@mixin button(){
    font-family: $heading;
    font-size: 25px;
    display: inline-block;
    vertical-align: middle;
    color: $tertiary;
    text-transform: capitalize;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    min-width: 200px;
    max-width: 100%;
    color: $tertiary;
    background-color: transparent;
    border: 1px solid $tertiary;
    border-radius: $button-radius;
    box-sizing: border-box;
    transition: $transition;
    &:hover {
        border-color: tint();
        background-color: tint();
    }
    &:active,
    &.active {
        color: $light;
        border-color: $tertiary;
        background-color: $tertiary;
    }
}

@mixin responsive_square {
    content:"";
    display: block;
    width: 100%;
    padding-top: 100%;
}
@mixin pseudo_cover {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
}
@mixin lighten($opacity: 0.3){
    @include pseudo_cover;
    background-color: #FFF;
    opacity: $opacity;
    mix-blend-mode: multiply;
}
@mixin darken($opacity: 0.1){
    @include pseudo_cover;
    background-color: #000;
    opacity: $opacity;
    mix-blend-mode: multiply;
}
@mixin arrow($colour: #FFF, $left: 6px){
    &:before,
    &:after {
        content:"";
        display: inline-block;
        position: absolute;
        border-radius: 2px;
        transition: $transition;
    }
    &:before { /* horizontal */
        top: 50%;
        margin-top: -1px;
        left: 0px;
        width: 15px;
        height: 2px;
        background-color: $colour;
    }
    &:after { /* arrowhead */
        left: $left;
        top: 9px;
        width: 8px;
        height: 8px;
        border-top: 2px solid $colour;
        border-right: 2px solid $colour;
        transform: rotate(45deg);
    }
}
